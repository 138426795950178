<script lang="ts">
  // your script goes here

  import Button from '$lib/components/Button.svelte';
  import TextField from '$lib/components/TextField.svelte';
  import type { ClientUserStore } from 'shared/src';
  import { createEventDispatcher, onDestroy } from 'svelte';
  import LoadingSpinner from '$lib/components/LoadingSpinner.svelte';
  import { errorProvider } from '$lib/error-handling/errorProvider';
  import { API } from 'shared/src/utils/api/api';
  import { getApiPaths, getUserStore, updateSessionUserWithJoins } from '$lib/stores/getUserStore';
  import type { UserWithJoins } from 'shared/definitions/user-object-definitions';
  import type { AuthFlowParameters } from 'shared/definitions/auth-object-definitions';
  import { twMerge } from 'tailwind-merge';
  import { omit } from 'shared/src/utils/omit';
  import { session } from '$app/stores';
  import { browser } from '$app/env';
  import type { Unsubscriber } from 'svelte/store';

  /**
   * Will be undefined when used outside the app.
   */
  export let clientUserStore: ClientUserStore | undefined = undefined;

  export let parameters: AuthFlowParameters = {};

  export let connectMode: boolean = false;

  export let buttonActionText = 'Sign In';

  export let autofocus = false;

  let clazz = '';
  export { clazz as class };

  let destroySessionSubscription: Unsubscriber | undefined;
  if (browser) {
    destroySessionSubscription = session.subscribe(() => {});
  }

  const api = new API(getApiPaths());

  let usernameOrEmail: string;
  let password: string;
  let loginError: string | false;
  let state: 'inputting' | 'waiting' | 'errored' | 'done' = 'inputting';
  let wrongUsernamePasswordCount = 0;

  export function cleanup() {
    if (destroySessionSubscription) {
      destroySessionSubscription();
    }
    usernameOrEmail = '';
    password = '';
    loginError = '';
    state = 'inputting';
  }

  onDestroy(cleanup);

  const dispatch = createEventDispatcher<{
    loginComplete: UserWithJoins;
    connectComplete: UserWithJoins;
    forgottenPassword: null;
    register: null;
  }>();

  async function loginAttempt() {
    try {
      state = 'waiting';

      // Remove the redirect from parameters -- we'll handle that internally:
      const parametersWithoutRedirect = omit(parameters, 'loginRedirect');

      let userWithJoins: UserWithJoins;
      if (!connectMode) {
        userWithJoins = clientUserStore
          ? await clientUserStore.api.login(usernameOrEmail, password, parametersWithoutRedirect)
          : await api.login(usernameOrEmail, password, parametersWithoutRedirect);
      } else {
        userWithJoins = await api.connectLocalLoginToOAuth(usernameOrEmail, password, parameters);
      }

      // Update the client session:
      updateSessionUserWithJoins(userWithJoins, session);

      // Now create a user store, to prevent having to reload the userWithJoins:
      if (!clientUserStore) {
        await getUserStore({ userWithJoins });
      }

      if (connectMode) {
        dispatch('connectComplete', userWithJoins);
      } else {
        dispatch('loginComplete', userWithJoins);
      }
      state = 'done';
    } catch (error: any) {
      state = 'errored';
      if (error?.status === 400 && error?.message) {
        loginError = error.message;
      } else if (error?.status === 401) {
        loginError = [
          'Wrong username or password. Typo?',
          'Still wrong... could be either your username or password...',
          "Sorry, that's not right either.",
        ][wrongUsernamePasswordCount++ % 3]!;
      } else if (error.status === 403) {
        loginError = error.message;
      } else if (error.status === 429) {
        loginError = "Whoah, slow down there! You've exceeded the rate limit for login attempts.";
      } else if (error.status >= 500) {
        loginError = 'Sorry, the uTheory server encountered an error. Please try again later.';
        errorProvider.error(error);
      } else {
        errorProvider.error(error);
        console.error(error);
        loginError = 'Unable to connect to uTheory server. Please check your internet connection.';
      }
    }
  }

  function onInput() {
    if (state !== 'waiting') state = 'inputting';
    loginError = false;
  }

  onDestroy(cleanup);
</script>

<form
  class={twMerge(
    `
  flex
  flex-grow
  flex-col
  sm:space-y-6
  space-y-2
  items-center
  pb-3`,
    clazz
  )}
  on:submit={(evt) => {
    evt.preventDefault();
  }}
>
  <TextField
    {autofocus}
    class="w-full"
    bind:value={usernameOrEmail}
    on:input={onInput}
    label="Username or email"
    autocomplete="username"
    required
  />
  <TextField
    class="w-full"
    type="password"
    bind:value={password}
    on:input={onInput}
    label="Password"
    autocomplete="current-password"
    customValidityMessage={loginError || ''}
    localValidationError={!!loginError}
    showErrorWhenFocused
    required
  />
  <Button
    disabled={!(password && usernameOrEmail) || state === 'waiting' || state === 'done'}
    on:click={loginAttempt}
    class="w-full h-12 text-sm font-normal"
  >
    {#if state !== 'waiting' && state !== 'done'}
      {buttonActionText}
    {:else}
      <LoadingSpinner />
    {/if}
  </Button>
</form>
